import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';
import App from './App';
import Footer from "./components/Footer";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode><App /></React.StrictMode>,);

const fc = document.getElementById('footer');
const footer = createRoot(fc);
footer.render(<React.StrictMode><Footer/></React.StrictMode>,);
