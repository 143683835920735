import React from 'react';
import "./Footer.css"
function Footer() {
    return (
        <div className="footer">
            KalpKari™ - conversation based directory & marketplace
        </div>
    );
}

export default Footer;
